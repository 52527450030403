
import { defineComponent, onMounted } from 'vue';
import SexView from '@/components/SexViewAV.vue'
import AgeView from '@/components/AgeViewAV.vue'
import LiveView from '@/components/LiveViewAV.vue'
import MotivationView from '@/components/MotivationViewAV.vue'
import LastView from '@/components/LastViewAV.vue'

export default defineComponent({
  name: 'AVHome',
  components: {
    SexView,
    AgeView,
    LiveView,
    MotivationView,
    LastView
  },
  
  data: function(){
    return{
      count:0,
      SignInURL:""
    }
  },

  methods: {
    CountUp(){
      this.count ++
    },
    
    JumpSignInURL(){
        this.SignInURL = "http://abehiroshi.la.coocan.jp/"
        window.location.href = this.SignInURL
    }
  },

  mounted(){
    const setFillHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight);

    // 初期化
    setFillHeight();
  }
});
