import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Nijikano from '../views/Nijikano.vue'
import RELACS from '../views/RELACS.vue'
import EC from '../views/ECHome.vue'
import AV from '../views/AVHome.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/nijikano',
    name: 'Nijikano',
    component: Nijikano
  },
  {
    path: '/',
    name: 'RELACS',
    component:RELACS
  },
  {
    path: '/ec',
    name: 'EC',
    component:EC
  },
  {
    path: '/high',
    name: 'AV',
    component:AV
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
