
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'RELACS',
  components: {
  },
  
  data: function(){
    return{
      Open:false,
      SignUpURL:"",
      PolicyURL:"",
      InitialValue:"",
      OptionPlace: [
        {value:"https://lin.ee/k94PqtC", name:'仙台'},
        {value:"https://lin.ee/WpzGSMD", name:'千葉'},
        {value:"https://lin.ee/qNDTNdW", name:'新宿'},
        {value:"https://lin.ee/2vFeppa", name:'北千住'},
        {value:"https://lin.ee/jbzSWqu", name:'厚木'},
        {value:"https://lin.ee/t18G9uS", name:'大宮'},
      ]
    }
  },

  methods: {
    JumpSignUpURL(){
      this.SignUpURL = "https://adlocate.jp/link.php?i=5dfaf8129bcaf&m=649a410fa92fe"
      window.location.href = this.SignUpURL
    },

    JumpPolicy(){
      this.PolicyURL = "https://relacs.jp/privacypolicy/"
      window.location.href = this.PolicyURL
    },

    PlaceChoice(e:any){
      window.location.href = e.target.value
    }
  },

  mounted(){
    setTimeout(() => {
      this.Open = true;
    }, 1);

    const setFillHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
          }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight);

    // 初期化
    setFillHeight();
  }
});
